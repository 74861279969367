import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { selectLanguages } from "../../store/languages/languages.selector";

import { updateEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { selectUsers } from "../../store/users/users.selector";
import axios from "axios";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const LanguageEditDialog = ({ open, handleClose, language }) => {
  const { enqueueSnackbar } = useSnackbar();

  const allLanguages = useSelector(selectLanguages);

  // Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  const currentLanguageName = language.languageName
  const currentLanguageCode = language.languageCode

  const handleSubmit = async (values, { resetForm }) => {
    const { languageName, languageCode } = values;

    if (
      languageName === language.languageName &&
      languageCode === language.languageCode
    ) {
      resetForm();
      handleClose();
      return;
    }

    // Close the dialog immediately
    handleClose();

    const updatedAt = new Date();
    const auth = getAuth();

    try {
      updateEntry(FIREBASE_COLLECTION_NAMES.LANGUAGES, language.id, {
        languageCode,
        languageName,
        updatedAt,
      });

      enqueueSnackbar(`Language "${languageName}" was successfully edited.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${languageName}" Language Edited`;
      const text =
        `The language "${languageName}" got edited by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `Language Name: ${languageName} ${
            currentLanguageName !== languageName
                ? `(previously: ${currentLanguageName})`
                : ""
        }\n` +
        `Language Code: ${languageCode} ${
            currentLanguageCode !== languageCode
                ? `(previously: ${currentLanguageCode})`
                : ""
        }\n`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to edit the language "${languageName}". Please try again.`,
        { variant: "error" }
      );
      console.log("editing language failed: ", error);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    languageName: Yup.string()
      .test("is-language-name-unique", "Already used", (value) => {
        if (!value || value === language.languageName) {
          return true; // Ignore validation if the value is the same as the current language name
        }
        return (
          allLanguages.filter((lang) => {
            return lang.languageName === value;
          }).length === 0
        );
      })
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{
        languageCode: language.languageCode,
        languageName: language.languageName,
      }}
      validationSchema={FORM_VALIDATION}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ resetForm }) => (
        <Form>
          <Dialog
            open={open}
            onClose={() => {
              resetForm();
              handleClose();
            }}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle>Edit Language</DialogTitle>
            <DialogContent>
              <Stack
                direction="column"
                spacing={2}
                marginY={2}
                alignItems="start"
              >
                <FormikTextfield
                  name="languageName"
                  fullWidth={true}
                  label="Language Name"
                  variant="standard"
                />
                <FormikTextfield
                  name="languageCode"
                  fullWidth={true}
                  label="Language Code"
                  variant="standard"
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ color: "red" }}
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <FormikButton type="submit" sx={{ color: "green" }}>
                Save
              </FormikButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default LanguageEditDialog;

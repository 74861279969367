import { useField } from "formik";

import Switch from "@mui/material/Switch";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";

const FormikSwitch = ({ name, label, ...otherProps }) => {
  const [field] = useField(name);

  const configSwitch = {
    ...field,
    ...otherProps,
    checked: field.value,
    inputProps: { "aria-label": "controlled" },
  };

  return (
      <FormControlLabel
          control={<Switch {...configSwitch} />}
          label={label}
          labelPlacement="start"
      />
  );
};

export default FormikSwitch;

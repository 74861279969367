import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from "@mui/material";
import { selectUsersListSearchString } from "../../store/users/users.selector";
import { setUsersListSearchString } from "../../store/users/users.action";
import {setGroupsListSearchString} from "../../store/groups/groups.action";

const UserListSearchBar = () => {
    const dispatch = useDispatch();

    const [query, setQuery] = useState(localStorage.getItem("searchQuery") || "");

    const searchString = useSelector(selectUsersListSearchString);

    useEffect(() => {
        dispatch(setUsersListSearchString(query));

        localStorage.setItem("searchQuery", query);
    }, [query]);

    const handleChange = (event) => {
        setQuery(event.target.value)
    };

    const handleClear = () => {
        dispatch(setUsersListSearchString(""));
        setQuery("")
        localStorage.setItem("searchQuery", "");
    }

    return (
        <TextField
            variant="outlined"
            value={searchString}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {query !== "" && (
                            <IconButton onClick={() => handleClear("")}>
                                <ClearIcon />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

export default UserListSearchBar;
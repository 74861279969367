import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
    IconButton,
    Typography
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ClearIcon } from "@mui/x-date-pickers";
import {PLATFORMS_TYPE} from "../../utils/helpers/constants";

const ConfigurationSearchBar = ({
                                    handleSearch,
                                    query,
                                    selectedGroup,
                                    handleGroups,
                                    allGroups,
                                    selectedPlatform,
                                    handlePlatformChange,
                                    selectedScreenSize,
                                    handleScreenSizeChange,
                                    selectedLanguage,
                                    handleLanguageChange,
                                    selectedFeature,
                                    handleFeatureChange,
                                    selectedApp,
                                    handleAppChange,
                                    numberOfConfigurations,
                                    filteredConfig,
                                    screenSizes,
                                    apps,
                                    features,
                                    languages
                                }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    return (
        <Box display="flex" flexDirection="column" gap="20px">
            {allGroups.length >= 1 && (
                <FormControl>
                    <InputLabel id="configuration-overview-select-label">Group</InputLabel>
                    <Select
                        labelId="configuration-overview-select-label"
                        label="Group"
                        value={selectedGroup}
                        onChange={handleGroups}
                        fullWidth={true}
                    >
                        {allGroups.map(({ id, groupName }) => (
                            <MenuItem key={id} value={id}>
                                {groupName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <TextField
                variant="outlined"
                value={query}
                onChange={(event) => handleSearch(event.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {query !== "" && (
                                <IconButton onClick={() => handleSearch("")}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />

            <Box display="flex" flexDirection="row" gap="20px">
                {/* Platform filter */}
                <Box sx={{ margin: 0, p: 0 }} width={windowWidth < 500 ? "100%" : "20%"} marginY={1}>
                    <FormControl fullWidth={true}>
                        <InputLabel id="configurations-overview-sort-label">Platform</InputLabel>
                        <Select
                            labelId="configurations-overview-sort-label"
                            label="Platform"
                            value={selectedPlatform}
                            onChange={handlePlatformChange}
                        >
                            {PLATFORMS_TYPE.map((platform, index) => (
                                <MenuItem key={index} value={platform.value}>
                                    {platform.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Screen Size filter */}
                <Box sx={{ margin: 0, p: 0 }} width={windowWidth < 500 ? "100%" : "20%"} marginY={1}>
                    <FormControl fullWidth={true}>
                        <InputLabel id="screen-size-select-label">Screen Size</InputLabel>
                        <Select
                            labelId="screen-size-select-label"
                            label="Screen Size"
                            value={selectedScreenSize}
                            onChange={handleScreenSizeChange}
                        >
                            {screenSizes.map((size, index) => (
                                <MenuItem key={index} value={size}>
                                    {`${size} inches`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Language filter */}
                <Box sx={{ margin: 0, p: 0 }} width={windowWidth < 500 ? "100%" : "20%"} marginY={1}>
                    <FormControl fullWidth={true}>
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select
                            labelId="language-select-label"
                            label="Language"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                        >
                            {languages.map((language, index) => (
                                <MenuItem key={index} value={language.id}>
                                    {language.languageName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Feature filter */}
                <Box sx={{ margin: 0, p: 0 }} width={windowWidth < 500 ? "100%" : "20%"} marginY={1}>
                    <FormControl fullWidth={true}>
                        <InputLabel id="feature-select-label">Feature</InputLabel>
                        <Select
                            labelId="feature-select-label"
                            label="Feature"
                            value={selectedFeature}
                            onChange={handleFeatureChange}
                        >
                            {features.map((feature, index) => (
                                <MenuItem key={index} value={feature.id}>
                                    {feature.featureName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* App filter */}
                <Box sx={{ margin: 0, p: 0 }} width={windowWidth < 500 ? "100%" : "20%"} marginY={1}>
                    <FormControl fullWidth={true}>
                        <InputLabel id="app-select-label">App</InputLabel>
                        <Select
                            labelId="app-select-label"
                            label="App"
                            value={selectedApp}
                            onChange={handleAppChange}
                        >
                            {apps.map((app, index) => (
                                <MenuItem key={index} value={app.id}>
                                    {app.appName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Box display="flex" position="relative" justifyContent="flex-end" sx={{ m: 0, p: 0, mt:2 }}>
                <Typography color="grey" position="absolute" right="0" bottom="0">
                    {filteredConfig.length === 0 ? null : numberOfConfigurations}
                </Typography>
            </Box>
        </Box>
    );
};

export default ConfigurationSearchBar;

/* eslint-disable react-hooks/rules-of-hooks */

import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { selectEnhancedUser } from "../../store/user/user.selector";
import { selectUsers } from "../../store/users/users.selector";
import { USER_ROLE, EMAILER_URL } from "../../utils/helpers/constants";
import { updateEntry, FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikSelect from "../formik-select/select.component";
import FormikSwitch from "../formik-switch/formik-switch.component";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef } from "react";
import axios from "axios";

const UserEditDialog = ({ open, handleClose, user }) => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector(selectEnhancedUser);
    const formikRef = useRef(null);

    const currentUserName = user.displayName
    const currentUserRole = user.role
    const currentUserEmail = user.email

    // Admin users
    const users = useSelector(selectUsers);
    const adminEmails = users
        .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
        .map((admin) => admin.notificationEmail);

    const handleSubmit = async (values, { resetForm }) => {
        const { fullName, role, enableNotification, notificationEmail, customNotificationEmail } = values;
        if (
            fullName === user.displayName &&
            user.role === role &&
            user.enableNotification === enableNotification &&
            user.notificationEmail === notificationEmail &&
            user.customNotificationEmail === !!customNotificationEmail
        ) {
            handleClose();
            resetForm();
            return;
        }

        // Close the dialog immediately
        handleClose();

        const auth = getAuth();
        const updatedAt = new Date();
        try {
            await updateEntry(FIREBASE_COLLECTION_NAMES.USERS, user.email, {
                displayName: fullName,
                role,
                updatedAt,
                enableNotification,
                notificationEmail,
                customNotificationEmail,
            });

            // Email start
            const formattedDate = formatTimestamp({
                seconds: new Date().getTime() / 1000,
                nanoseconds: 0,
            });
            const subject = `"${fullName}" User Edited`;
            const text =
                `The user "${fullName}" got edited by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
                `User Name: ${fullName} ${
                    currentUserName !== fullName
                        ? `(previously: ${currentUserName})`
                        : ""
                }\n` +
                `Role: ${role === USER_ROLE.ADMIN ? USER_ROLE.ADMIN : USER_ROLE.EDITOR} ${
                    currentUserRole !== role
                        ? `(previously: ${currentUserRole})`
                        : ""
                }\n` +
                `Email Address: ${user.email}${
                    currentUserEmail !== user.email
                        ? `(previously: ${currentUserEmail})`
                        : ""
                }`;
            const recipients = [...adminEmails];
            console.log(recipients)

            const emailData = {
                to: recipients,
                subject,
                text,
            };



            if (recipients.length > 0 && recipients[0] !== '') {
                axios
                    .post(EMAILER_URL, emailData)
                    .then((response) => {
                        console.log("Email sent successfully:", response.data);
                    })
                    .catch((error) => {
                        console.error("Error sending email:", error.response || error.message);
                    });
            } else {
                console.error("Error: No recipients defined");
            }

            // Email end
            resetForm();
            enqueueSnackbar(`User "${user.email}" was successfully edited.`, {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar(
                `Failed to edit the user "${fullName}". Please try again.`,
                {
                    variant: "error",
                }
            );
            console.log("Editing user failed: ", error);
        }
    };

    // Reset form on dialog close
    useEffect(() => {
        if (!open && formikRef.current) {
            formikRef.current.resetForm();
        }
    }, [open]);

    const FORM_VALIDATION = Yup.object().shape({
        fullName: Yup.string().required("Required"),
        role: Yup.string().required("Required"),
        enableNotification: Yup.bool().required("Required"),
        notificationEmail: Yup.string()
            .email("Invalid email address")
            .when("enableNotification", {
                is: true,
                then: Yup.string()
                    .notRequired()
                    .when("customNotificationEmail", {
                        is: true,
                        then: Yup.string().required("Custom email is required"),
                        otherwise: Yup.string().notRequired(),
                    }),
                otherwise: Yup.string().notRequired(),
            }),
        customNotificationEmail: Yup.bool(),
    });

    return (
        <Formik
            initialValues={{
                fullName: user.displayName,
                role: user.role,
                enableNotification: user.enableNotification,
                notificationEmail: user.notificationEmail || "",
                customNotificationEmail: user.customNotificationEmail || false,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
            innerRef={formikRef}
            enableReinitialize={true}
        >
            {({ resetForm, values, isValid , setFieldValue}) => {
                useEffect(() => {
                    if (!open) return;

                    if (!formikRef.current) return;

                    if (!values.enableNotification && !values.customNotificationEmail) {
                        setFieldValue("notificationEmail", "");
                    }
                    else if (values.enableNotification && !values.customNotificationEmail) {
                        setFieldValue("notificationEmail", user.email);
                    }
                    else if (values.enableNotification && values.customNotificationEmail) {
                        if (values.notificationEmail === user.email) {
                            setFieldValue("notificationEmail", "");
                        } else {
                            setFieldValue("notificationEmail", user.notificationEmail);

                        }
                    }
                    else if (!values.enableNotification && values.customNotificationEmail) {
                        setFieldValue("notificationEmail", "");
                        setFieldValue("customNotificationEmail", false);
                    }

                }, [open, values.enableNotification, values.customNotificationEmail]);

                return (
                <Form>
                    <Dialog
                        open={open}
                        onClose={() => {
                            handleClose();
                            resetForm();
                        }}
                        fullWidth
                        maxWidth="xs"
                    >
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent>
                            <Stack spacing={2} alignItems="center">
                                <Typography variant="h8" sx={{ mx: 3 }} style={{ fontWeight: "bold", padding:"10px", margin:"10px", letterSpacing:".2vh", borderBottom: "1px solid rgba(0, 0, 0, 0.2)"}}>
                                    General
                                </Typography>
                                <FormikTextfield
                                    name="fullName"
                                    fullWidth
                                    label="Full Name"
                                    variant="standard"
                                />
                                <FormikSelect
                                    name="role"
                                    label="Role"
                                    options={[
                                        { value: USER_ROLE.ADMIN, label: USER_ROLE.ADMIN },
                                        { value: USER_ROLE.EDITOR, label: USER_ROLE.EDITOR },
                                        { value: USER_ROLE.VIEWER, label: USER_ROLE.VIEWER },
                                    ]}
                                    disabled={currentUser.email === user.email}
                                />
                                <Typography variant="h8" sx={{ mx: 3 }} style={{ fontWeight: "bold", padding:"10px", margin:"10px", letterSpacing:".2vh", borderBottom: "1px solid rgba(0, 0, 0, 0.2)"}}>
                                    Notification
                                </Typography>
                                <FormikSwitch
                                    name="enableNotification"
                                    label="Receive email notifications when a license or configuration gets added, edited or deleted?"
                                />

                                <FormikSwitch
                                    name="customNotificationEmail"
                                    label="Use an email address different to the account email address?"
                                    disabled={!values.enableNotification}
                                />

                                <FormikTextfield
                                    name="notificationEmail"
                                    fullWidth={true}
                                    label="Email Address"
                                    variant="standard"
                                    disabled={!values.customNotificationEmail}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleClose();
                                    resetForm();
                                }}
                                sx={{ color: "red" }}
                            >
                                Cancel
                            </Button>
                            <FormikButton type="submit" sx={{ color: "green" }} disabled={!isValid}>
                                Save
                            </FormikButton>
                        </DialogActions>
                    </Dialog>
                </Form>
                )
            }}
        </Formik>
    );
};

export default UserEditDialog;

// @ts-nocheck
import { Fragment, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { where } from "firebase/firestore";

import {
  addEntry,
  countDocs,
  FIREBASE_COLLECTION_NAMES,
} from "../../utils/firebase/firebase-firestore.utils";
import { addUser } from "../../utils/firebase/firebase-functions.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";

import React from "react";

import FormikSelect from "../formik-select/select.component";
import { useSelector } from "react-redux";
import { selectUsers } from "../../store/users/users.selector";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";
import Typography from "@mui/material/Typography";

const INITIAL_FORM_STATE = {
  displayName: "",
  role: USER_ROLE.ADMIN,
  email: "",
  password: "",
  confirmPassword: "",
  notificationEmail: "",
  enableNotification: false,
};

const FORM_VALIDATION = Yup.object().shape({
  displayName: Yup.string().required("Required"),
  role: Yup.string().oneOf(Object.values(USER_ROLE)).required("Required"),
  enableNotification: Yup.bool().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required")
    .test(
      "checkEmailAvailability",
      "Email address already in use",
      async function (value) {
        const emailCount = await countDocs(
          FIREBASE_COLLECTION_NAMES.USERS,
          where("email", "==", value || "")
        );
        return emailCount === 0;
      }
    ),
  notificationEmail: Yup.string()
    .email("Invalid email address")
    .when("enableNotification", {
      is: true,
      then: Yup.string()
        .required("Required")
        .test(
          "checkNotificationEmailAvailability",
          "Email address already in use",
          async function (value) {
            const emailCount = await countDocs(
              FIREBASE_COLLECTION_NAMES.USERS,
              where("notificationEmail", "==", value)
            );
            return emailCount === 0;
          }
        ),
      otherwise: Yup.string().notRequired(),
    }),
  password: Yup.string()
    .min(6, "Needs to be 6 characters or longer")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Password doesn't match"),
});

const UserAddDialog = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef();

  //Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter(
      (user) =>
        user.role === USER_ROLE.ADMIN && user.enableNotification === true
    )
    .map((admin) => admin.notificationEmail);

  const handleSubmit = async (values, { resetForm }) => {
    const auth = getAuth();

    try {
      const {
        displayName,
        role,
        email,
        password,
        notificationEmail,
        enableNotification,
      } = values;

      // Close the dialog immediately
      handleClose();

      // TODO: Transaction/Batch Write

      const data = await addUser(email, password);

      // Users Collection
      await addEntry(FIREBASE_COLLECTION_NAMES.USERS, email, {
        displayName,
        email,
        role,
        notificationEmail,
        enableNotification,
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${displayName}" User Added`;
      const text =
        `The user "${displayName}" got added by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `User Name: ${displayName} \n` +
        `Role: ${
          role === USER_ROLE.ADMIN ? USER_ROLE.ADMIN : USER_ROLE.EDITOR
        } \n` +
        `Email Address: ${email}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      if (recipients.length > 0) {
        axios
          .post(EMAILER_URL, emailData)
          .then((response) => {
            console.log("Email sent successfully");
          })
          .catch((error) => {
            console.error("Error sending email:", error);
          });
      }

      //Emailer end

      enqueueSnackbar(`User "${email}" was successfully created.`, {
        variant: "success",
      });

      resetForm();
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        enqueueSnackbar("The email adress is already in use", {
          variant: "info",
        });
      } else {
        enqueueSnackbar(
          // eslint-disable-next-line no-undef
          `Failed to add the user "${displayName}". Please try again.`,
          {
            variant: "error",
          }
        );
        console.log("user creation failed: ", error);
      }
    }
  };

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  return (
    <Fragment>
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
      >
        {({ setFieldValue, values }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (!values.enableNotification) {
              setFieldValue("notificationEmail", "");
            }
          }, [values.enableNotification, setFieldValue]);

          return (
            <Form>
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                  <Stack spacing={2} marginY={2} alignItems="center">
                    <Typography
                      variant="h8"
                      sx={{ mx: 3 }}
                      style={{
                        fontWeight: "bold",
                        padding: "10px",
                        margin: "10px",
                        letterSpacing: ".2vh",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      General
                    </Typography>
                    <FormikTextfield
                      name="displayName"
                      fullWidth={true}
                      label="Full Name"
                      variant="standard"
                    />
                    <FormikSelect
                      name="role"
                      label="Role"
                      options={[
                        { value: USER_ROLE.ADMIN, label: USER_ROLE.ADMIN },
                        { value: USER_ROLE.EDITOR, label: USER_ROLE.EDITOR },
                        { value: USER_ROLE.VIEWER, label: USER_ROLE.VIEWER },
                      ]}
                    />
                    <FormikTextfield
                      name="email"
                      fullWidth={true}
                      label="Email Address"
                      variant="standard"
                    />
                    <FormikTextfield
                      name="password"
                      fullWidth={true}
                      label="Password"
                      variant="standard"
                      type="password"
                    />
                    <FormikTextfield
                      name="confirmPassword"
                      fullWidth={true}
                      label="Confirm Password"
                      variant="standard"
                      type="password"
                    />
                    <Typography
                      variant="h8"
                      sx={{ mx: 3 }}
                      style={{
                        fontWeight: "bold",
                        padding: "10px",
                        margin: "10px",
                        letterSpacing: ".2vh",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      Notification
                    </Typography>
                    <FormikSelect
                      name="enableNotification"
                      label="Notification"
                      options={[
                        { value: false, label: "Disabled" },
                        { value: true, label: "Enabled" },
                      ]}
                    />
                    <FormikTextfield
                      name="notificationEmail"
                      fullWidth={true}
                      label="Notification Email Address"
                      variant="standard"
                      disabled={!values.enableNotification}
                    />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} sx={{ color: "red" }}>
                    Cancel
                  </Button>
                  <FormikButton type="submit" sx={{ color: "green" }}>
                    Save
                  </FormikButton>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default UserAddDialog;

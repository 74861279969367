import React, { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

const FormikSelect = ({ name, label, options, disabled, isAdmin, userTypeValue, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    onChange: handleChange,
    variant: "outlined",
  };

  const sortedOptions = useMemo(() => {
    if (!options || options.length === 0) return [];
  
    const optionsToSort = [...(options || [])];
    if (optionsToSort.length === 0) return [];
  
    if (typeof optionsToSort[0] === "string") {
      return optionsToSort.sort((a, b) => (a || '').localeCompare(b || ''));
    } else {
      return optionsToSort.sort((a, b) => {
        const labelA = a.label || '';
        const labelB = b.label || '';
        return labelA.localeCompare(labelB);
      });
    }
  }, [options]);

  const configFormControl = {
    fullWidth: true,
    error: meta.touched && !!meta.error,
  };

  return (
    <FormControl {...configFormControl} variant="outlined">
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        {...configSelect}
        disabled={disabled}
        label={label}
        labelId={`${name}-label`}
        id={`${name}-select`}
      >
        {sortedOptions.map((option) => (
          typeof option === 'string' ? (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ) : (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={!isAdmin && option.value <= userTypeValue}
            >
              {option.label}
            </MenuItem>
          )
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelect;


import { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { selectEnhancedUser } from "../../store/user/user.selector";
import { signOutUser } from "../../utils/firebase/firebase-auth.utils";
import {updateEntry, FIREBASE_COLLECTION_NAMES} from "../../utils/firebase/firebase-firestore.utils"

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Menu, MenuItem } from "@mui/material";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import useHideOnScroll from "./useHideOnScroll";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { USER_ROLE } from "../../utils/helpers/constants";

const drawerWidth = 240;

const Header = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const hide = useHideOnScroll();
  const navigate = useNavigate();

  const currentUser = useSelector(selectEnhancedUser);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);

  const [enableNotification, setEnableNotification] = useState(currentUser?.enableNotification ?? false);
  const [customNotificationEmail, setCustomNotificationEmail] = useState(!!currentUser?.customNotificationEmail);
  const [notificationEmail, setNotificationEmail] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  useEffect(() => {
    if (currentUser) {
      setEnableNotification(currentUser.enableNotification);
      setNotificationEmail(currentUser.notificationEmail || "");
    }
  }, [currentUser]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNotificationMenuOpen = () => {
    setEnableNotification(currentUser.enableNotification);
    setNotificationEmail(currentUser.notificationEmail);
    setCustomNotificationEmail(currentUser.customNotificationEmail);
    setNotificationMenuOpen(true);
    setAnchorEl(null);
  };

  const handleNotificationMenuClose = () => {
    setNotificationMenuOpen(false);
  };

  const handleCustomEmailChange = (event) => {
    setNotificationEmail(event.target.value);
  };

  const handleSignOut = async () => {
    try {
      await signOutUser();
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Sign out failed. Please try again.", {
        variant: "error",
      });
      console.log("sign out failed: ", error);
    }
  };

  const handleNotificationSettings = async () => {

    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.USERS, currentUser.email, {
        updatedAt: new Date(),
        enableNotification,
        customNotificationEmail,
        notificationEmail,
      });
      enqueueSnackbar(`User "${currentUser.email}" was successfully edited.`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Failed to edit the user "${currentUser.displayName}". Please try again.`, { variant: "error" });
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        LICENSE MANAGEMENT
      </Typography>
      <Divider />
      {currentUser ? (
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("user/licenses")}>
              <ListItemText primary="Licenses" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("user/configurations")}>
              <ListItemText primary="Configurations" />
            </ListItemButton>
          </ListItem>
          {currentUser.role === USER_ROLE.ADMIN ? (
            <Fragment>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/apps")}>
                  <ListItemText primary="Apps" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/features")}>
                  <ListItemText primary="Features" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/languages")}>
                  <ListItemText primary="Languages" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/groups")}>
                  <ListItemText primary="Groups" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/users")}>
                  <ListItemText primary="Users" />
                </ListItemButton>
              </ListItem>
            </Fragment>
          ) : null}
          <ListItem disablePadding>
            <ListItemButton onClick={handleSignOut}>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
      ) : null}
    </Box>
  );

  const container =
    props.window !== undefined ? () => props.window().document.body : undefined;

  return (
    <Box>
      <AppBar
        component="nav"
        position="fixed"
        style={{
          transform: hide ? "translateY(-100%)" : "translateY(0)",
          transition: "transform 0.3s ease-in-out",
        }}
        sx={{ backgroundColor: "#403C8C" }}
      >
        <Toolbar>
          {currentUser ? (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: windowWidth < 1025 ? "block" : "none" }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Typography
              sx={{ flexGrow: 1, display: { xs: "block", sm: "none" } }}
            >
              LICENSE MANAGEMENT
            </Typography>
          )}

          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems={"center"} justifyContent="center" gap="20px">
              <img
                style={
                  windowWidth < 600
                    ? { width: "30px", height: "30px" }
                    : { width: "40px", height: "40px" }
                }
                alt="logo"
                src="/LogoBrainssistanceWhite.png"
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  alignSelf: "center",
                  display: { xs: "none", sm: "block" },
                }}
              >
                LICENSE MANAGEMENT
              </Typography>
            </Box>

            {currentUser ? (
              <Box
                sx={{
                  display: windowWidth > 1025 ? "flex" : "none",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ color: "#fff", pb: "5px" }}
                  onClick={() => navigate("user/licenses")}
                >
                  Licenses
                </Button>
                <Button
                  sx={{ color: "#fff", pb: "5px" }}
                  onClick={() => navigate("user/configurations")}
                >
                  Configurations
                </Button>
                {currentUser.role === USER_ROLE.ADMIN ? (
                  <Fragment>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/apps")}
                    >
                      Apps
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/features")}
                    >
                      Features
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/languages")}
                    >
                      Languages
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/groups")}
                    >
                      Groups
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/users")}
                    >
                      Users
                    </Button>
                  </Fragment>
                ) : null}

                <Button
                  sx={{ color: "#fff", pb: "5px" }}
                  onClick={handleSignOut}
                >
                  Sign Out
                </Button>
              </Box>
            ) : null}

            {currentUser ? (
              <Box display="flex" alignItems="center">
                <Button
                  onClick={handleMenuOpen}
                  style={{
                    background: "none",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton sx={{ color: "#fff" }}>
                    <Avatar />
                  </IconButton>
                  <Typography sx={{ color: "#fff", mr: 2, cursor: "pointer" }}>
                    {currentUser.displayName}
                  </Typography>
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem disabled>{`Status: ${currentUser.role}`}</MenuItem>
                  <Divider />
                  <MenuItem onClick={handleNotificationMenuOpen}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Notification Settings
                  </MenuItem>
                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Sign Out
                  </MenuItem>
                </Menu>
              </Box>
            ) : null}
          </Box>

          <Dialog
            open={notificationMenuOpen}
            onClose={handleNotificationMenuClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Notification Settings"}
            </DialogTitle>
            <DialogContent>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNotificationSettings();
                  handleNotificationMenuClose();
                }}
              >
                <Box display="flex" flexDirection="column" gap={2}>
                  <FormControlLabel
                      control={
                        <Switch
                            checked={enableNotification}
                            onChange={(e) => {
                              setEnableNotification(e.target.checked);
                              if (!e.target.checked) {
                                setCustomNotificationEmail(false);
                                setNotificationEmail("");
                              } else {
                                setNotificationEmail(currentUser.email);
                              }
                            }}
                            name="email-notification"
                        />
                      }
                      label="Receive email notifications when a license or configuration gets added, edited or deleted?"
                      labelPlacement="start"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                  />


                  <FormControlLabel
                      control={
                        <Switch
                            disabled={!enableNotification}
                            name="use-custom-email"
                            checked={customNotificationEmail}
                            onChange={(e) => {
                              setCustomNotificationEmail(e.target.checked);
                              if (!e.target.checked) {
                                currentUser.enableNotification = false;
                                setNotificationEmail(currentUser.email);
                              } else {
                                currentUser.enableNotification = true;
                                setEnableNotification(true)
                                setNotificationEmail("");
                              };
                            }}

                        />
                      }
                      label="Use an email address different to the account email address?"
                      labelPlacement="start"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                  />
                </Box>


                <TextField
                    disabled={!customNotificationEmail}
                    label="Email Address"
                    variant="outlined"
                    type="email"
                    value={notificationEmail}
                    onChange={handleCustomEmailChange}
                    fullWidth
                    margin="normal"
                />

                <DialogActions>
                  <Button sx={{ color: "red" }} onClick={handleNotificationMenuClose}>
                    Close
                  </Button>
                  <Button
                      type="submit"
                      sx={{ color: "green" }}
                      disabled={
                        !(
                            enableNotification && !customNotificationEmail
                        ) &&
                          !(enableNotification && customNotificationEmail && notificationEmail) &&
                          !(!enableNotification && !customNotificationEmail)
                      }
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: windowWidth < 1025 ? "block" : "none",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;

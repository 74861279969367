import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectAppListSearchString } from "../../store/apps/apps.selector";
import { setAppListSearchString } from "../../store/apps/apps.action";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from "@mui/material";

const AppListSearchBar = () => {
    const dispatch = useDispatch();

    const [query, setQuery] = useState(localStorage.getItem("searchQuery") || "");

    const searchString = useSelector(selectAppListSearchString);

    useEffect(() => {
        dispatch(setAppListSearchString(query));

        localStorage.setItem("searchQuery", query);
    }, [query]);

    const handleChange = (event) => {
        setQuery(event.target.value)
    };

    const handleClear = () => {
        dispatch(setAppListSearchString(""));
        setQuery("")
        localStorage.setItem("searchQuery", "");
    }

    return (
        <TextField
            variant="outlined"
            value={searchString}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
                ),
                endAdornment: (
                <InputAdornment position="end">
                    {query !== "" && (
                        <IconButton onClick={() => handleClear("")}>
                            <ClearIcon />
                        </IconButton>
                    )}
                </InputAdornment>
                )
            }}
        />
    );
}

export default AppListSearchBar;